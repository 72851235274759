import React from 'react';
import { Result } from 'antd';
import IntegrationParams from '../types/IntegrationParams';

interface IIntegrationParams {
    integrationParams: IntegrationParams;
}

function IntegrationParamsComponent(WrappedComponent: any) {
    return class extends React.Component<IIntegrationParams> {
        render() {
            const { token, unti_id, activity_uuid } = this.props.integrationParams;

            return (
                <>
                    {token && unti_id && activity_uuid ? (
                        <WrappedComponent
                            {...this.props}
                            token={token}
                            unti_id={unti_id}
                            activity_uuid={activity_uuid}
                        >
                            {this.props.children}
                        </WrappedComponent>
                    ) : (
                        <Result
                            status="warning"
                            title="Извините, произошла ошибка. Возвращайтесь позже."
                        />
                    )}
                </>
            );
        }
    };
}

export default IntegrationParamsComponent;
