import React, { Suspense } from 'react';
import IntegrationParams from './components/IntegrationParams';
import { connect } from 'react-redux';
import AppState from './types/AppState';
import IntegrationParamsType from './types/IntegrationParams';
import { Spin } from 'antd';

const Chart = React.lazy(() => import('./components/Chart'));

interface IApp {
    integrationParams: IntegrationParamsType;
    fetchConfig: boolean;
    fetchConfigError: any;
    fetchResult: boolean;
    fetchResultError: any;
    result: any;
}

function App(props: IApp) {
    let content;
    if (
        !props.fetchConfig &&
        props.fetchConfigError === null &&
        !props.fetchResult &&
        props.fetchResultError === null
    ) {
        content = (
            <Suspense fallback={<></>}>
                <Chart />
            </Suspense>
        );
    } else if (props.fetchConfigError !== null && props.fetchResultError !== null) {
        content = (
            <div className="kios-widget__no-data">
                <p>Данных нет</p>
            </div>
        );
    } else {
        content = (
            <div className="kios-widget__preloader">
                <Spin size="large" tip={'Загрузка...'} />
            </div>
        );
    }

    return <div className="kios-widget__app">{content}</div>;
}

const mapStateToProps = (store: AppState) => {
    return {
        integrationParams: store.general.integrationParams,
        fetchConfig: store.general.fetchConfig,
        fetchConfigError: store.general.fetchConfigError,
        fetchResult: store.general.fetchResult,
        fetchResultError: store.general.fetchResultError,
        result: store.general.result,
    };
};

export default connect(mapStateToProps)(IntegrationParams(App));
