const actions = {
    general: {
        SET_INTEGRATION_PARAMS: '@PT140_KIOS_WIDGET/SET_INTEGRATION_PARAMS',
        FETCH_CONFIG: '@PT140_KIOS_WIDGET/FETCH_CONFIG',
        FETCH_CONFIG_SUCCESS: '@PT140_KIOS_WIDGET/FETCH_CONFIG_SUCCESS',
        FETCH_CONFIG_ERROR: '@PT140_KIOS_WIDGET/FETCH_CONFIG_ERROR',
        FETCH_RESULT: '@PT140_KIOS_WIDGET/FETCH_RESULT',
        FETCH_RESULT_SUCCESS: '@PT140_KIOS_WIDGET/FETCH_RESULT_SUCCESS',
        FETCH_RESULT_ERROR: '@PT140_KIOS_WIDGET/FETCH_RESULT_ERROR',
    },
};

export default actions;
