import storeService from './storeService';
import actions from '../store/actions/types';
import axios from 'axios';

class IntegrationService {
    public async loadConfig() {
        const currentState = storeService.getStore().getState().general;
        const { activity_uuid } = currentState.integrationParams;

        storeService.getStore().dispatch({
            type: actions.general.FETCH_CONFIG,
        });

        return axios.get(
            'https://bypass.2035.university/api/activity/' + activity_uuid + '/get_config',
            {
                headers: {
                    'Content-Type': 'application/json',
                },
                params: {},
            }
        );
    }

    public async getResult() {
        const currentState = storeService.getStore().getState().general;
        const source_activity_uuid = currentState.source_activity_uuid;
        const { unti_id, token } = currentState.integrationParams;
        // a

        storeService.getStore().dispatch({
            type: actions.general.FETCH_RESULT,
        });

        return axios.post(
            'https://bypass.2035.university/api/activity/' + source_activity_uuid + '/getresult',
            {
                unti_id: unti_id,
                token: token,
                t: 'c8d46d341bea4fd5bff866a65ff8aea9',
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    }
}

const integrationService = new IntegrationService();

export default integrationService;
