import storeService from './storeService';
import actions from '../store/actions/types';
import IntegrationParams from '../types/IntegrationParams';

class DispatherService {
    setParamsByElement = (element: HTMLElement | null): void => {
        if (element) {
            const token = element.getAttribute('data-user-token'),
                unti_id = element.getAttribute('data-unti-id'),
                activity_uuid = element.getAttribute('data-activity-uuid');

            console.log(token, unti_id, activity_uuid);

            storeService.getStore().dispatch({
                type: actions.general.SET_INTEGRATION_PARAMS,
                payload: {
                    token: token,
                    unti_id: unti_id,
                    activity_uuid: activity_uuid,
                } as IntegrationParams,
            });
        }
    };

    // setShouldRedirect = (shouldRedirect: boolean) => {
    //     storeService.getStore().dispatch({
    //         type: actions.general.SET_SHOULD_REDIRECT,
    //         payload: shouldRedirect,
    //     });
    // };
}

const dispatherService = new DispatherService();
export default dispatherService;
