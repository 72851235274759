import { Store } from 'redux';

class StoreService {
    store: Store | null;
    constructor(store: Store | null = null) {
        this.store = store;
    }

    setStore = (store: Store) => {
        this.store = store;
    };

    getStore = (): Store => {
        if (this.store !== null) {
            return this.store;
        } else throw new Error('Trying to access unitialized store');
    };
}
const storeService = new StoreService();

export default storeService;
